var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "data-list-container",
      attrs: { id: "data-list-list-view" }
    },
    [
      _c("add-new-data-sidebar", {
        attrs: { isSidebarActive: _vm.addNewDataSidebar },
        on: {
          closeSidebar: function($event) {
            _vm.addNewDataSidebar = false
          }
        }
      }),
      _c(
        "vs-table",
        {
          key: _vm.componentKey,
          ref: "table",
          attrs: {
            multiple: "",
            pagination: "",
            "max-items": _vm.itemsPerPage,
            search: "",
            data: _vm.users
          },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function(ref) {
                var data = ref.data
                return [
                  _c(
                    "tbody",
                    _vm._l(data, function(tr, indextr) {
                      return _c(
                        "vs-tr",
                        { key: indextr, attrs: { data: tr } },
                        [
                          _c("vs-td", [
                            _c("p", { staticClass: "rule-txt" }, [
                              _vm._v(_vm._s(tr.name))
                            ])
                          ]),
                          _c("vs-td", [
                            _c("p", { staticClass: "rule-txt" }, [
                              _vm._v(_vm._s(tr.limit))
                            ])
                          ]),
                          _c(
                            "vs-td",
                            [
                              _c(
                                "vs-chip",
                                {
                                  staticClass: "rule-txt",
                                  attrs: {
                                    color: _vm.getRuleTypeColor(tr.type)
                                  }
                                },
                                [_vm._v(_vm._s(tr.type))]
                              )
                            ],
                            1
                          ),
                          _c("vs-td", [
                            _c("p", { staticClass: "rule-txt" }, [
                              _vm._v(_vm._s(tr.timeFormat))
                            ])
                          ]),
                          _c("vs-td", [
                            _c("p", { staticClass: "rule-txt" }, [
                              _vm._v(_vm._s(tr.delAfterFormat))
                            ])
                          ]),
                          _c(
                            "vs-td",
                            [
                              _c(
                                "vs-chip",
                                {
                                  staticClass: "rule-txt",
                                  attrs: {
                                    color: _vm.getRuleActiveColor(tr.active)
                                  }
                                },
                                [_vm._v(_vm._s(tr.active))]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    }),
                    1
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.selected,
            callback: function($$v) {
              _vm.selected = $$v
            },
            expression: "selected"
          }
        },
        [
          _c(
            "div",
            {
              staticClass:
                "flex flex-wrap-reverse items-center flex-grow justify-between",
              attrs: { slot: "header" },
              slot: "header"
            },
            [
              _c(
                "div",
                { staticClass: "flex flex-wrap-reverse items-center" },
                [
                  _c(
                    "vs-dropdown",
                    {
                      staticClass: "cursor-pointer mr-4 mb-4",
                      attrs: { "vs-trigger-click": "" }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "p-4 shadow-drop rounded-lg d-theme-dark-bg cursor-pointer flex items-center justify-center text-lg font-medium w-32"
                        },
                        [
                          _c("span", { staticClass: "mr-2" }, [
                            _vm._v("Actions")
                          ]),
                          _c("feather-icon", {
                            attrs: {
                              icon: "ChevronDownIcon",
                              svgClasses: "h-4 w-4"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "vs-dropdown-menu",
                        [
                          _c(
                            "vs-dropdown-item",
                            {
                              attrs: { disabled: _vm.selected.length === 0 },
                              on: { click: _vm.menuEnableClick }
                            },
                            [_c("span", [_vm._v("Enable")])]
                          ),
                          _c(
                            "vs-dropdown-item",
                            {
                              attrs: { disabled: _vm.selected.length === 0 },
                              on: { click: _vm.menuDisableClick }
                            },
                            [_c("span", [_vm._v("Disable")])]
                          ),
                          _c(
                            "vs-dropdown-item",
                            {
                              attrs: { disabled: _vm.selected.length === 0 },
                              on: { click: _vm.menuDeleteClick }
                            },
                            [_c("span", [_vm._v("Delete")])]
                          ),
                          _c(
                            "vs-dropdown-item",
                            {
                              attrs: {
                                disabled:
                                  _vm.selected.length === 0 ||
                                  _vm.selected.length > 1
                              },
                              on: { click: _vm.menuEditClick }
                            },
                            [_c("span", [_vm._v("Edit")])]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-between text-lg font-medium text-base text-primary border border-solid border-primary",
                      on: {
                        click: function($event) {
                          _vm.addNewDataSidebar = true
                        }
                      }
                    },
                    [
                      _c("feather-icon", {
                        attrs: { icon: "PlusIcon", svgClasses: "h-4 w-4" }
                      }),
                      _c(
                        "span",
                        { staticClass: "ml-2 text-base text-primary" },
                        [_vm._v("Add New")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "vs-dropdown",
                {
                  staticClass: "cursor-pointer mb-4 mr-4",
                  attrs: { "vs-trigger-click": "" }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "p-4 border border-solid border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
                    },
                    [
                      _c("span", { staticClass: "mr-2" }, [
                        _vm._v(
                          _vm._s(
                            _vm.currentPage * _vm.itemsPerPage -
                              (_vm.itemsPerPage - 1)
                          ) +
                            " - " +
                            _vm._s(
                              _vm.users.length -
                                _vm.currentPage * _vm.itemsPerPage >
                                0
                                ? _vm.currentPage * _vm.itemsPerPage
                                : _vm.users.length
                            ) +
                            " of " +
                            _vm._s(_vm.users.length)
                        )
                      ]),
                      _c("feather-icon", {
                        attrs: {
                          icon: "ChevronDownIcon",
                          svgClasses: "h-4 w-4"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "vs-dropdown-menu",
                    [
                      _c(
                        "vs-dropdown-item",
                        {
                          on: {
                            click: function($event) {
                              _vm.itemsPerPage = 4
                            }
                          }
                        },
                        [_c("span", [_vm._v("4")])]
                      ),
                      _c(
                        "vs-dropdown-item",
                        {
                          on: {
                            click: function($event) {
                              _vm.itemsPerPage = 10
                            }
                          }
                        },
                        [_c("span", [_vm._v("10")])]
                      ),
                      _c(
                        "vs-dropdown-item",
                        {
                          on: {
                            click: function($event) {
                              _vm.itemsPerPage = 15
                            }
                          }
                        },
                        [_c("span", [_vm._v("15")])]
                      ),
                      _c(
                        "vs-dropdown-item",
                        {
                          on: {
                            click: function($event) {
                              _vm.itemsPerPage = 20
                            }
                          }
                        },
                        [_c("span", [_vm._v("20")])]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "template",
            { slot: "thead" },
            [
              _c(
                "vs-th",
                { staticClass: "rule-name", attrs: { "sort-key": "name" } },
                [_vm._v("Name")]
              ),
              _c(
                "vs-th",
                { staticClass: "rule-limit", attrs: { "sort-key": "limit" } },
                [_vm._v("Limit")]
              ),
              _c(
                "vs-th",
                { staticClass: "rule-type", attrs: { "sort-key": "type" } },
                [_vm._v("Type")]
              ),
              _c(
                "vs-th",
                { staticClass: "rule-time", attrs: { "sort-key": "time" } },
                [_vm._v("Time")]
              ),
              _c(
                "vs-th",
                {
                  staticClass: "rule-del-after",
                  attrs: { "sort-key": "delAfter" }
                },
                [_vm._v("Delete after")]
              ),
              _c(
                "vs-th",
                { staticClass: "rule-active", attrs: { "sort-key": "active" } },
                [_vm._v("Active")]
              )
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }