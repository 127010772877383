<template>
  <div id="data-list-list-view" class="data-list-container">

    <add-new-data-sidebar :isSidebarActive="addNewDataSidebar" @closeSidebar="addNewDataSidebar = false" />

    <vs-table ref="table" multiple v-model="selected" pagination :max-items="itemsPerPage" search :data="users" :key="componentKey">

      <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">

        <div class="flex flex-wrap-reverse items-center">

          <!-- ACTION - DROPDOWN -->
          <vs-dropdown vs-trigger-click class="cursor-pointer mr-4 mb-4">

            <div class="p-4 shadow-drop rounded-lg d-theme-dark-bg cursor-pointer flex items-center justify-center text-lg font-medium w-32">
              <span class="mr-2">Actions</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>

            <vs-dropdown-menu>
              <vs-dropdown-item @click="menuEnableClick" :disabled="selected.length === 0">
                <span>Enable</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="menuDisableClick" :disabled="selected.length === 0">
                <span>Disable</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="menuDeleteClick" :disabled="selected.length === 0">
                <span>Delete</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="menuEditClick" :disabled="selected.length === 0 || selected.length > 1">
                <span>Edit</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>

          <!-- ADD NEW -->
          <div class="p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-between text-lg font-medium text-base text-primary border border-solid border-primary" @click="addNewDataSidebar = true">
            <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
            <span class="ml-2 text-base text-primary">Add New</span>
          </div>
        </div>

        <!-- ITEMS PER PAGE -->
        <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4">
          <div class="p-4 border border-solid border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
            <span class="mr-2">{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} - {{ users.length - currentPage * itemsPerPage > 0 ? currentPage * itemsPerPage : users.length }} of {{ users.length }}</span>
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
          <vs-dropdown-menu>

            <vs-dropdown-item @click="itemsPerPage=4">
              <span>4</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=10">
              <span>10</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=15">
              <span>15</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=20">
              <span>20</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>

      <template slot="thead">
        <vs-th sort-key="name" class="rule-name">Name</vs-th>
        <vs-th sort-key="limit" class="rule-limit">Limit</vs-th>
        <vs-th sort-key="type" class="rule-type">Type</vs-th>
        <vs-th sort-key="time" class="rule-time">Time</vs-th>
        <vs-th sort-key="delAfter" class="rule-del-after">Delete after</vs-th>
        <vs-th sort-key="active" class="rule-active">Active</vs-th>
      </template>

      <template slot-scope="{data}">
        <tbody>
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">

            <vs-td>
              <p class="rule-txt">{{ tr.name }}</p>
            </vs-td>

            <vs-td>
              <p class="rule-txt">{{ tr.limit }}</p>
            </vs-td>

            <vs-td>
              <vs-chip :color="getRuleTypeColor(tr.type)" class="rule-txt">{{ tr.type }}</vs-chip>
            </vs-td>

            <vs-td>
              <p class="rule-txt">{{ tr.timeFormat }}</p>
            </vs-td>

            <vs-td>
              <p class="rule-txt">{{ tr.delAfterFormat }}</p>
            </vs-td>

            <vs-td>
              <vs-chip :color="getRuleActiveColor(tr.active)" class="rule-txt">{{ tr.active }}</vs-chip>
            </vs-td>
          </vs-tr>
        </tbody>
      </template>
    </vs-table>
  </div>
</template>

<script>
import AddNewDataSidebar from '@/components/AddNewRuleSidebar'
import { EventBus } from '@/event-bus'
import Amplify from 'aws-amplify'

export default {
  components: {
    AddNewDataSidebar
  },
  data() {
    return {
      selected: [],
      users: [],
      itemsPerPage: 4,
      isMounted: false,
      addNewDataSidebar: false,
      componentKey: 0
    }
  },
  computed: {
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },
  },
  methods: {
    getRuleTypeColor(type) {
      if (type === 'keyword') return "rgb(75, 110, 154)"
      if (type === 'click') return "rgb(168, 108, 183)"
      return "rgb(197, 197, 197)"
    },
    getRuleActiveColor(active) {
      if (active === 'enable') return "rgb(108, 183, 131)"
      if (active === 'disable') return "rgb(224, 103, 103)"
      return "rgb(197, 197, 197)"
    },
    formatData(data) {
      // formats data received from API
      let formattedData = data.map((item) => {
        let obj = {
          name: item.name,
          limit: item.limit,
          type: item.type,
          timeFormat: `${item.time} ${item.timeUnit}`,
          delAfterFormat: `${item.delAfter} ${item.delAfterUnit}`,
          id: item.id,
          time: item.time,
          timeUnit: item.timeUnit,
          delAfter: item.delAfter,
          delAfterUnit: item.delAfterUnit
        }
        if (item.active) {
          obj['active'] = 'enable'
        } else {
          obj['active'] = 'disable'
        }
        return obj
      });
      return formattedData
    },
    readUserRules() {
      const dataUR = this.$store.state.userRuleList
      if (dataUR.length > 0) {
        this.users = this.formatData(dataUR)
      }
    },
    menuEnableClick() {
      if (this.selected.length > 0) {
        for (let itemSel of this.selected) {
          // Remove from state
          this.$store.dispatch('enableUserRule', itemSel.id)
          Amplify.Auth.currentSession().then(apiSession => {
            // INI Get user
            const paramsActiveTru = {
              active: true
            }
            this.$axios.put(`/userrules/${itemSel.id}`, paramsActiveTru, {
              headers: { 'Authorization': 'Bearer ' + apiSession.idToken.jwtToken }
            }).then(response => {
              if (response.status === 200) {
                this.readUserRules()
                this.selected = []
                this.componentKey += 1
              }
            })
            // FIN Get user
          })
        }
        this.readUserRules()
      }
    },
    menuDisableClick() {
      if (this.selected.length > 0) {
        for (let itemSel of this.selected) {
          // Remove from state
          this.$store.dispatch('disableUserRule', itemSel.id)
          Amplify.Auth.currentSession().then(apiSession => {
            // INI Get user
            const paramsActiveFal = {
              active: false
            }
            this.$axios.put(`/userrules/${itemSel.id}`, paramsActiveFal, {
              headers: { 'Authorization': 'Bearer ' + apiSession.idToken.jwtToken }
            }).then(response => {
              if (response.status === 200) {
                this.readUserRules()
                this.selected = []
                this.componentKey += 1
              }
            })
            // FIN Get user
          })
        }
        this.readUserRules()
      }
    },
    menuDeleteClick() {
      if (this.selected.length > 0) {
        for (let itemSel of this.selected) {
          // Remove from state
          this.$store.dispatch('removeUserRule', itemSel.id)
          Amplify.Auth.currentSession().then(apiSession => {
            // INI Get user
            this.$axios.delete(`/userrules/${itemSel.id}`, {
              headers: { 'Authorization': 'Bearer ' + apiSession.idToken.jwtToken }
            }).then(response => {
              if (response.status === 200) {
                this.readUserRules()
                this.selected = []
                this.componentKey += 1
              }
            })
            // FIN Get user
          })
        }
        this.readUserRules()
      }
    },
    menuEditClick() {
      if (this.selected.length == 1) {
        this.addNewDataSidebar = true
        const itemSel = this.selected[0]
        EventBus.$emit('user-rules-edit-rule', itemSel)
        this.selected = []
      }
    }
  },
  created() {
    this.readUserRules()
  },
  mounted() {
    this.isMounted = true
    EventBus.$on('user-rules-updated', () => {
      this.selected = []
      this.readUserRules()
      this.componentKey += 1
    })
  }
}
</script>

<style scoped lang="scss">
.rule-name {
  width: 30%;
}
.rule-limit {
  width: 10%;
}
.rule-type {
  width: 10%;
}
.rule-time {
  width: 20%;
}
.rule-del-after {
  width: 20%;
}
.rule-active {
  width: 10%;
}

.rule-txt {
  font-size: 1.2rem;
}

#data-list-list-view {
  .vs-con-table {
    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 20px;
          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }
          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }
        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check {
        padding: 0 15px !important;
      }
      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>

<script>
export default {
  data() {
    return {
      transProps: {
        name: 'flip-list'
      },
      firstElement: null,
      totalRows: 7,
      currentPage: 1,
      perPage: 3,
      sortBy: '',
      rowSel: false,
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      ipSelectec: '',
      filterOn: [],
      items: [],
      fieldsAnalytics: [
        {
          key: 'whiteIP',
          label: 'IP Address',
          class: 'table-ip-col',
          sortable: true,
          sortDirection: 'desc',
        },
        {
          key: 'description',
          label: 'Descripción',
          class: 'table-description-col',
          sortable: true,
          sortDirection: 'desc'
        },
        {
          key: 'idAction',
          label: 'Acción',
          class: 'table-action-col',
        },
        {
          key: 'dateAdded',
          label: 'Día de alta',
          class: 'table-dateadd-col',
          sortable: true,
          sortDirection: 'desc'
        },
      ],
    }
  },
  methods: {
    chargeItems() {
      // const dataItems = { data: { total: 1, items: [{ blockIP: '97.85.12.48', host: 'host-uno.net', nClicks: 1, ranking: 0, blockDate: '2019/12/05 17:32:15' }, { blockIP: '154.75.1.4', host: 'host-dos.net', nClicks: 5, ranking: 100, blockDate: '2019/12/05 18:32:15' }] } }
      // this.totalRows = dataItems.data.total
      this.items.push({ whiteIP: '97.85.12.48', description: 'Casa de Pedro', dateAdded: '2019/12/05 17:32:15', idAction: 1 })
      this.items.push({ whiteIP: '154.75.1.4', description: 'Telefono de Juan', dateAdded: '2019/12/05 18:32:15', idAction: 2 })
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    onRowSelected(items) {
      if (items.length > 0) {
        this.rowSel = true
        this.ipSelectec = items[0].blockIP
      } else {
        this.rowSel = false
        this.ipSelectec = ''
      }
    },
  },
  mounted() {
    this.chargeItems()
  },
}
</script>